import React from "react";
import './footer.css';
import {FaFacebookF} from 'react-icons/fa';
import {FiInstagram} from 'react-icons/fi';
import {IoLogoTwitter} from 'react-icons/io';
import {BsLinkedin} from 'react-icons/bs';
import {BsGithub} from 'react-icons/bs';

const Footer = () => {
  return (
    <footer>
      <a href="#" className="footer__logo">ARTHUR HAUS</a>

      <ul className="permalinks">
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://facebook.com/arthur.haus.1" target="_blank" rel="noreferrer"><FaFacebookF/></a>
        <a href="https://github.com/arthur1302" target="_blank" rel="noreferrer"><BsGithub/></a>
        <a href="https://www.linkedin.com/in/arthur-haus-95b820175/" target="_blank" rel="noreferrer"><BsLinkedin/></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Arthur Haus. All rights reserved.</small>
      </div>
    </footer>
  );
}

export default Footer;
import React from 'react';
import './SkillRating.css';

const SkillRating = ({ rating }) => {
  const maxRating = 5;
  const filledBullets = Array.from({ length: rating }, (_, index) => index + 1);
  const emptyBullets = Array.from({ length: maxRating - rating }, (_, index) => index + rating + 1);

  return (
    <div className="skill-rating">
      {filledBullets.map((bullet) => (
        <span key={bullet} className="bullet filled"></span>
      ))}
      {emptyBullets.map((bullet) => (
        <span key={bullet} className="bullet empty"></span>
      ))}
    </div>
  );
};

export default SkillRating;

import IMG1 from '../assets/portfolio1.jpg';
import IMG2 from '../assets/portfolio2.jpg';
import IMG3 from '../assets/portfolio3.jpg';
import IMG4 from '../assets/portfolio4.jpg';
import IMG5 from '../assets/portfolio5.png';
import IMG6 from '../assets/portfolio6.jpg';

import IMG10 from '../assets/arnaud1.png';
import IMG11 from '../assets/svk.jpg';
import IMG12 from '../assets/fluvius.png';

import AVTR1 from '../assets/avatar1.jpg';
import AVTR2 from '../assets/avatar2.jpg';
import AVTR3 from '../assets/avatar3.jpg';
import AVTR4 from '../assets/avatar4.jpg';

const PORTFOLIO_DATA = [
  {
    id: 1,
    image: IMG1,
    title: "Resume",
    sort: "Personal",
    description: "React",
    github: "",
    demo: "arthurhaus.be"
  },
  {
    id: 2,
    image: IMG2,
    title: "Pet shelter",
    sort: "School",
    description: "Java Spring Boot",
    github: "",
    demo: ""
  },
  {
    id: 3,
    image: IMG3,
    title: "Soccer Academy",
    sort: "External",
    github: "",
    demo: "https://www.usacademy.be"
  }
];

const PORTFOLIO_EXTRA_DATA = [
  {
    id: 1,
    image: IMG10,
    title: "Logo design gardener",
    sort: "External"
  },
  {
    id: 2,
    image: IMG11,
    title: "SVK (WIP)",
    sort: "School"
  },
  {
    id: 3,
    image: IMG12,
    title: "Fluvius",
    sort: "School"
  }
];

const TESTIMONIAL_DATA = [
  {
    id: 1,
    avatar: AVTR1,
    name: "Arnaud Temmerman",
    review: "Correcte samenwerking. Mooie logo's."
  },
  {
    id: 2,
    avatar: AVTR2,
    name: "Michael",
    review: "De website die Arthur leverde was precies wat ik wou. Snel, simpel en mooi."
  }
];


export { PORTFOLIO_DATA, TESTIMONIAL_DATA, PORTFOLIO_EXTRA_DATA };
import React from "react";
import './about.css';
import ME from '../../assets/me.png';
import ME2 from '../../assets/me2.png';
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME2} alt="About Image" />
          </div>
        </div>

      <div className="about__content">
        <div className="about__cards">
          <article className="about__card">
            <FaAward className="about__icon"/>
            <h5>Experience</h5>
            <small>3+ Years Student</small>
          </article>

          <article className="about__card">
            <FiUsers className="about__icon"/>
            <h5>Position</h5>
            <small>Web Developer</small>
          </article>

          <article className="about__card">
            <VscFolderLibrary className="about__icon"/>
            <h5>Projects</h5>
            <small>Personal/School</small>
          </article>
          </div>

          <p>
          Hello! I'm Arthur, a third-year web development student from Ghent, Belgium. Driven, collaborative, and persistent, I'm passionate about web technologies. With a foundation in IT from high school, I've explored various programming languages for web development.
          </p>
          <p>
          While my professional journey is just beginning, I've tackled personal and school projects, enhancing my skills. Eager to learn and innovate, I'm excited to contribute to the dynamic world of web development. Let's create something amazing together!
          </p>

          <a href="#contact" className="btn btn-primary">Let's Talk</a>
          
        </div>
      </div>
    </section>
  );
}

export default About;
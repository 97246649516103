import React from "react";
import './header.css';
import CTA from "./CTA";
import ME from "../../assets/me.png";
import ME2 from '../../assets/me2.png';
import HeaderSocials from "./HeaderSocials";

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Arthur Haus</h1>
        <h5 className="text-light">Student web development</h5>
        <CTA/>
        <HeaderSocials/>

        <div className="me">
          <img src={ME2} alt="me" />
        </div>

        <a href="#contact" className="scroll-down">Scroll Down</a>
      </div>
    </header>
  );
}

export default Header;
import React from "react";
import { BsLinkedin, BsGithub, BsDribbble } from "react-icons/bs";

const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href="https://www.linkedin.com/in/arthur-haus-95b820175/" target="_blank" rel="noreferrer"><BsLinkedin/></a>
      <a href="https://github.com/arthur1302" target="_blank" rel="noreferrer"><BsGithub/></a>
    </div>
  );
}

export default HeaderSocials;

/*
<a href="https://www.dribbble.com" target="_blank" rel="noreferrer"><BsDribbble/></a>
*/
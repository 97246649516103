import React from "react";
import "./services.css";
import {BiCheck} from "react-icons/bi";

const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
  <article className="service">
    <div className="service__head">
      <h3>Web Development</h3>
    </div>
    <ul className="service__list">
      <li>
        <BiCheck className="service__list-icon"/>
        <p>Professional website development.</p>
      </li>
      <li>
        <BiCheck className="service__list-icon"/>
        <p>Front-end and back-end development.</p>
      </li>
      <li>
        <BiCheck className="service__list-icon"/>
        <p>Focus on visual user experiences.</p>
      </li>
      <li>
        <BiCheck className="service__list-icon"/>
        <p>Regular maintenance and updates.</p>
      </li>
    </ul>
  </article>
  {/* End of service article */}

  <article className="service">
    <div className="service__head">
      <h3>Graphic Design and Branding</h3>
    </div>
    <ul className="service__list">
      <li>
        <BiCheck className="service__list-icon"/>
        <p>Logo and visual identity.</p>
      </li>
      <li>
        <BiCheck className="service__list-icon"/>
        <p>Social media graphic design.</p>
      </li>
      <li>
        <BiCheck className="service__list-icon"/>
        <p>Social media management.</p>
      </li>
      <li>
        <BiCheck className="service__list-icon"/>
        <p>Print and digital design.</p>
      </li>
    </ul>
  </article>
  {/* End of service article */}

  <article className="service">
    <div className="service__head">
      <h3>Application Development</h3>
      </div>
    <ul className="service__list">
      <li>
        <BiCheck className="service__list-icon"/>
        <p>Android apps (iOS = WIP).</p>
      </li>
      <li>
        <BiCheck className="service__list-icon"/>
        <p>User-friendly interface.</p>
      </li>
      <li>
        <BiCheck className="service__list-icon"/>
        <p>Application maintenance.</p>
      </li>
      <li>
        <BiCheck className="service__list-icon"/>
        <p>Future-oriented and evolving app development.</p>
      </li>
    </ul>
  </article>
  {/* End of service article */}
</div>

    </section>
  );
}

export default Services;
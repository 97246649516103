import React from "react";
import './experience.css';
import { BsPatchCheckFill } from 'react-icons/bs';
import SkillRating from "./SkillRating";

const Experience = () => {
  return (
    <section id='experience'>
      <h5>What Skills I have</h5>
      <h2>My Experience</h2>

      <div className='container experience__container'>
        <div className="experience__frontend">
          <h3>Front-end Development</h3>
          <div className="experience__content">
          <article className="experience__details">
            <BsPatchCheckFill className="experience__details-icon"/>
            <div>
              <h4>HTML/CSS</h4>
              <SkillRating rating={4} />
            </div>
          </article>
          <article className="experience__details">
            <BsPatchCheckFill className="experience__details-icon"/>
            <div>
              <h4>React.js</h4>
              <SkillRating rating={3} />
            </div>
          </article>
          <article className="experience__details">
            <BsPatchCheckFill className="experience__details-icon"/>
            <div>
              <h4>.NET/C#</h4>
              <SkillRating rating={2} />
            </div>
          </article>
          <article className="experience__details">
            <BsPatchCheckFill className="experience__details-icon"/>
            <div>
              <h4>Kotlin</h4>
              <SkillRating rating={2} />
            </div>
          </article>
          </div>
        </div>
        <div className="experience__backend">
          <h3>Back-end Development</h3>
          <div className="experience__content">
          <article className="experience__details">
            <BsPatchCheckFill className="experience__details-icon"/>
            <div>
              <h4>SQL</h4>
              <SkillRating rating={3} />
            </div>
          </article>
          <article className="experience__details">
            <BsPatchCheckFill className="experience__details-icon"/>
            <div>
              <h4>JavaScript</h4>
              <SkillRating rating={4} />
            </div>
          </article>
          <article className="experience__details">
            <BsPatchCheckFill className="experience__details-icon"/>
            <div>
              <h4>C#</h4>
              <SkillRating rating={2} />
            </div>
          </article>
          <article className="experience__details">
            <BsPatchCheckFill className="experience__details-icon"/>
            <div>
              <h4>Python</h4>
              <SkillRating rating={2} />
            </div>
          </article>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experience;